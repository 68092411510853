// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'core-js/stable'
import 'regenerator-runtime/runtime'

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();

// import stylesheets
import '../stylesheets/application.scss'

// import all image files in a folder:
require.context('../images', true);

// OneUI JavaScript
import './oneui/app';

